























import { PropType } from 'vue'
import mixins from 'vue-typed-mixins'

import residentMixin from '@/mixins/residentMixin'

import { MarketingBannerInterface } from '@/types/components'
import { VueCSSClass } from '@/types/index'

import MarketingBannerDescription from './MarketingBannerDescription.vue'
import MarketingBannerDiscount from './MarketingBannerDiscount.vue'

export default mixins(residentMixin).extend({
    components: {
        MarketingBannerDescription,
        MarketingBannerDiscount
    },
    props: {
        banner: { 
            type: Object as PropType<MarketingBannerInterface>,
            required: true
        },
        format: {
            type: String,
            default: 'panorama',
            validator: val => ['panorama', 'half-panorama', 'landscape'].includes(val)
        },
        isInCarousel: Boolean
    },

    computed: {
        tag(): string {
            return this.banner.link ? 'router-link' : 'div'
        },
        bannerImage(): string {
            let backgroundImage: string | undefined = ''
            switch(this.format) {
                case 'panorama':
                    backgroundImage = this.banner?.image?.url
                    break
                case 'half-panorama':
                    backgroundImage = this.banner?.imageMedium?.url
                    break
                case 'landscape':
                default:
                    backgroundImage = this.banner?.imageSmall?.url
                    break
            }

            return backgroundImage || (this.banner?.image?.url ? this.banner.image.url : '')
        },
        bannerStyle(): Partial<CSSStyleDeclaration> {
            return {
                backgroundImage: this.bannerImage ? `url("${this.bannerImage}")` : '',
                backgroundColor: this.banner.backgroundColor || ''
            }
        },
        bannerClass(): VueCSSClass {
            return {
                [`format-${this.format}`]: true,
                'has-bgcolor': Boolean(this.banner.backgroundColor),
                'has-bgimage': Boolean(this.banner.image),
                'desktop': this.$breakpoint.mdAndUp,
                'is-in-carousel': this.isInCarousel
            }
        },
        bannerLink(): string  {
            return this.banner.link ? `/residents/${this.$_residentId}${this.banner.link}` : ''
        }
    },
})
