




























import Vue, { PropType } from 'vue'

import Routes from '@/constants/routes'

import { SuggestionDto } from '@happytal/happydom-api'
import { Location } from 'vue-router'

import { HBtn } from '@happytal/bo-ui-library'
import SuggestionTile from './SuggestionTile.vue'
import VueHorizontal from "vue-horizontal"

export default Vue.extend({
    components: {
        HBtn,
        SuggestionTile,
        VueHorizontal,
    },

    props: {
        items: {
            type: Array as PropType<SuggestionDto[]>,
            default: () => []
        }
    },

    computed: {
        residentSuggestionsRoute(): Location {
            if (this.$aclChecker.some(this.$acl, [ 'isEhpadEmployee', 'isEhpadManager'])) {
                return {
                    name: Routes.ResidentSuggestionsPending
                }
            }
            
            return {
                name: Routes.ResidentSuggestionsToProcess
            }
        }
    },
})
