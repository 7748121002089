


















import { PropType } from 'vue'
import mixins from 'vue-typed-mixins'
import moment from 'moment'
import Cuttr from 'cuttr'

import { HCard, HIcon } from '@happytal/bo-ui-library'
import { ProductOffersDto, ServiceModelServicesDto, SuggestionDto, VariantDataDto } from '@happytal/happydom-api'

import {
    formatLocalDate
} from '@/modules/date'

import Routes from '@/constants/routes'
import Formats from '@/constants/formats'

import suggestionsMixin from '@/views/residents/suggestions/suggestionsMixin'

export default mixins(suggestionsMixin).extend({
  components: {
    HCard,
    HIcon
  },

  props: {
    suggestion: {
      type: Object as PropType<SuggestionDto>,
      default: null
    }
  },

  computed: {
    productOffers(): ProductOffersDto[] {
      return this.$store.getters["dataSuggestions/productOffers"]
    },
    serviceModelServices(): ServiceModelServicesDto[] {
      return this.$store.getters["dataSuggestions/serviceModelServices"]
    },
    variantData(): VariantDataDto[] {
      return this.$store.getters["dataSuggestions/variantData"]
    },
    residentSuggestionsRoute(): string {
      if (this.$aclChecker.some(this.$acl, [ 'isEhpadEmployee', 'isEhpadManager'])) {
          return Routes.ResidentSuggestionsPending
        }

        return Routes.ResidentSuggestionsToProcess
    },
    title(): string {
      return this.$_getSuggestionTitle(this.suggestion)
    },
    description(): string {
      return this.$_getSuggestionDescription(this.suggestion, this.productOffers, this.variantData, this.serviceModelServices)
    },
    quantity(): number | undefined {
      return this.$_getSuggestionQuantity(this.suggestion)
    },
    paymentType(): string | undefined {
      if (this.suggestion.type === 'Open') {
        return undefined
      }
      return this.$_getPaymentTypeLabel(this.suggestion)
    },
    price(): number | undefined {
      return this.$_getSuggestionPrice(this.suggestion)
    },
    createdBy(): string | undefined {
      return this.suggestion.createdBy?.name
    },
    createdOn(): string | undefined {
      return formatLocalDate(this.suggestion.createdOnUtc, Formats.DisplayDate)
    },
    imageUrl(): string | undefined {
      return this.$_getSuggestionImage(this.suggestion, this.serviceModelServices)
    }
  },

  methods: {
    truncateDescription(): void {
        new Cuttr('.suggestion-tile-description', {
            truncate: 'characters',
            length: 140
        })
    }
  },

  mounted(){
    this.truncateDescription()
  }
})
