<template>
    <div class="help-banners">
        <VueHorizontal :button="false" class="banners">
            <div
                class="help-banner"
                :style="getBannerStyle(banner)"
                v-for="(banner, bannerIndex) in banners"
                :key="bannerIndex"
                @click="onBannerClick(banner)"
            >
                <div class="banner-footer">
                    <div class="banner-title">{{ banner.description }}</div>
                </div>
            </div>
            <template v-slot:btn-prev>
                <HBtn icon="ChevronLeftIcon" icon-only :icon-size="25" size="large" />
            </template>
            <template v-slot:btn-next>
                <HBtn icon="ChevronRightIcon" icon-only :icon-size="25" size="large" />
            </template>
        </VueHorizontal>
        <div class="actions">
            <HBtn
                icon="NotebookIcon"
                class="help-btn"
                data-cy="help-btn"
                @click="goToHelpBtnClick"
            >Accéder à mes questions</HBtn>
        </div>
    </div>
</template>

<script>
    import VueHorizontal from 'vue-horizontal'

    import Routes from '@/constants/routes'

    import {
        HBtn
    } from '@happytal/bo-ui-library'

    export default {
        components: {
            VueHorizontal,
            HBtn
        },
        props: {
            banners: {
                type: Array,
                required: true
            }
        },
        methods: {
            onBannerClick (banner) {
                const link = _.get(banner, 'link', '')
                const matches = link.match(/(\d+)$/)
                const articleId = matches[1]
                this.$router.push({
                    name: Routes.ResidentHelpCategoryDetail,
                    params: {
                        resident_id: this.$route.params.resident_id,
                        article_id: articleId
                    }
                })
            },
            getBannerImage (banner) {
                if (this.$breakpoint.smAndDown) {
                    return _.get(banner, 'imageSmall[0].url', null)
                }
                else if (this.$breakpoint.mdAndDown) {
                    return _.get(banner, 'imageMedium[0].url', null)
                }
                else {
                    return _.get(banner, 'image[0].url', null)
                }
            },
            getBannerStyle (banner) {
                const bannerImage = this.getBannerImage(banner)
                return `background-image:url(${bannerImage})`
            },
            goToHelpBtnClick (e) {
                this.$router.push({
                    name: Routes.ResidentHelpCategory,
                    params: {
                        resident_id: this.$route.params.resident_id
                    }
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
@import '~@happytal/bo-ui-library/src/styles/variables';

.help-banners {

    .banners {

        @media (max-width: map-get($breakpoints, sm)) {
            ::v-deep .v-hl-btn-prev {
                left: 35px;
            }
            ::v-deep .v-hl-btn-next {
                right: 35px;
            }
        }

        .help-banner {
            position: relative;
            width: calc(50% - 20px);
            height: 321px;
            margin: 0px 40px 0px 0px;
            border-radius: 6px;
            text-align: center;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            overflow: hidden;
            cursor: pointer;

            @media (max-width: map-get($breakpoints, sm)) {
                width: 100%;
            }

            .banner-footer {
                position: absolute;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 50px;
                left: 0px;
                bottom: 0px;
                border-radius: 0px 0px 6px 6px;
                z-index: 2;
                background: rgba(0,0,0,.4);
                transition: all .5s ease;

                .banner-title {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 20px;
                    user-select: none;
                    color: white;

                    @media (max-width: map-get($breakpoints, sm)) {
                        font-size: 16px;
                    }
                }
            }
        }
        .help-banner:last-child {
            margin: 0px 0px 0px 0px;
        }
        .help-banner:hover {

            .banner-footer {
                background: rgba(var(--vs-primary),1);
            }
        }
    }
    .actions {
        margin: 23px 0px 0px 0px;
        text-align: right;

        @media (max-width: map-get($breakpoints, sm)) {
            text-align: center;
        }

        .help-btn {

        }
    }
}
</style>
