

















import Vue, { PropType } from 'vue'
import { Flicking, FlickingOptions, Plugin, ChangedEvent } from "@egjs/vue-flicking"
import { AutoPlay } from "@egjs/flicking-plugins"

export default Vue.extend({
    components: {
        Flicking
    },

    props: {
        options: {
            type: Object as PropType<FlickingOptions>,
            default: () => ({
                panelsPerView: 1,
                align: 'prev',
                circular: true
            })
        },
        numberOfItems: {
            type: Number,
            required: true
        },
        pagination: Boolean,
        autoplay: Boolean
    },

    data(){
        return {
            activeIndex: 0
        }
    },

    computed: {
        plugins(): Plugin[] {
            const plugins = [] as Plugin[]

            if(this.autoplay) {
                plugins.push(new AutoPlay({duration: 5000}))
            }

            return plugins
        },
    },

    mounted(){
        const slider = this.$refs.slider
    },

    methods: {
        moveTo(idx: number): void {
            const slider = this.$refs.slider as any
            slider.moveTo(idx)
        },
        onWillChange(event: ChangedEvent): void {
            this.activeIndex = event.index
        }
    }
})
