





















































import mixins from 'vue-typed-mixins'
import { AxiosResponse } from 'axios'
import appsettings from '@/appsettings'
import { sampleSize } from 'lodash'

import { SuggestionDto } from '@happytal/happydom-api'
import { Location } from 'vue-router'
import { MarketingBannerInterface } from '@/types/components'

import Routes from '@/constants/routes'
import Http from "@/http"
import ToastMixin from '@/mixins/toasts'
import institutionMixin from '@/mixins/institutionMixin'
import residentMixin from '@/mixins/residentMixin'
import bannersMixin from '@/mixins/bannersMixin'

import { HBtn } from '@happytal/bo-ui-library'
import ResidentHeader from '@/views/residents/components/ResidentHeader.vue'
import NoteCard from "@/components/NoteCard.vue";
import WelcomeMessage from './dashboard/WelcomeMessage.vue'
import SuggestionsSection from './dashboard/SuggestionsSection.vue'
//import ProductsSection from './dashboard/ProductsSection.vue'
import SliderBanners from '@/components/banners/SliderBanners.vue'
import HelpBanners from '@/components/banners/HelpBanners.vue'
//import SeparatorLine from '@/components/SeparatorLine.vue'

import { WelcomeMessageDto } from '@/models/strapi'

interface ProductData {
  type: string
  id: string
  variantIds?: string[]
  variantCode?: string
  title: string
  imageUrl: string
  minPrice: number,
  color: string | null,
  brand: string
  quantity: string
  offers: {
    id: number,
    price: number
    quantity: number
    shopId: number
    shopName: string
    stockedByShop: boolean
  }[]
}

interface ProductItem {
      id: string,
      title: string,
      price: number,
      image?: string,
      quantity?: string,
      route: Location
}

export default mixins(ToastMixin, residentMixin, institutionMixin, bannersMixin).extend({
  components: {
    HBtn,
    NoteCard,
    ResidentHeader,
    WelcomeMessage,
    SuggestionsSection,
    //ProductsSection,
    SliderBanners,
    //SeparatorLine,
    HelpBanners
  },

  data() {
    return {
      welcomeMessage: undefined as WelcomeMessageDto | undefined,
      suggestionsLoaded: false,
      //topProducts: [] as ProductItem[],
      //topProductsLoaded: false,
      banners: [] as MarketingBannerInterface[],
      bannersLoaded: false,
      helpBanners: [] as MarketingBannerInterface[],
      helpBannerLoaded: true
    }
  },

  computed: {
    pendingSuggestions(): SuggestionDto[] {
      return this.$store.getters["dataSuggestions/suggestionsPendingOrdered"].slice(0,9)
    },
    canViewHelpBanners(): boolean {
      return this.$aclChecker.some(this.$acl, [
        'isHappytalSupport',
        'isHappytalEmployee',
        'isFamily'
      ])
    },
    canShowBanners(): boolean {
      return this.bannersLoaded && this.banners?.length > 0
    },
    canShowHelpBanners(): boolean {
      return this.canViewHelpBanners && this.helpBannerLoaded && this.helpBanners?.length > 0
    }
  },

  async created() {
      this.$vs.loading()

      try {
          //Load welcome message
          try {
              const welcomeMessageResponse: AxiosResponse<WelcomeMessageDto[]> = await Http.apis.strapi.get(`/welcome-messages?institution.institution_id=0&institution.institution_id=${this.$_currentInstitutionId}`)
              const welcomeMessage = welcomeMessageResponse.data

              this.welcomeMessage = welcomeMessage.find( (message: WelcomeMessageDto) => message.institution.institution_id === this.$_currentInstitutionId) ?? welcomeMessage.find((message: WelcomeMessageDto) => message.institution.institution_id === 0)
          } catch (error: any) {
              this.$_showErrorToast(`Une erreur s'est produite pendant le chargement du message d'accueil.`)
          }

          // Load suggestions
          try {
              await this.$store.dispatch('dataSuggestions/fetchAllSuggestions', this.$_residentId)
          } catch (error: any) {
              console.error(error)
              this.$_showErrorToast(`Une erreur s'est produite pendant le chargement des suggestions.`)
          } finally {
              this.suggestionsLoaded = true
          }

          /*/ Load top products
          try {
              const topProductsResponse = await Http.apis.ehpad.get(`/items?application=happydom&frontCategoryIds=${appsettings.VUE_APP_TOP_CATEGORY_ID}&institutionId=${this.$_currentInstitutionId}&pageIndex=1&pageSize=36`)

              const topProducts: ProductData[] = topProductsResponse.data.items
              const sampleTopProducts: ProductData[] = topProducts.length > 9 ? sampleSize(topProducts, 9) : topProducts

              this.topProducts = sampleTopProducts.map((item: ProductData) => {
                  let route: Location = {} as Location

                  if (!item.id && item.variantIds?.length) {
                      route = {
                          name: Routes.CatalogVariant,
                          params: {
                          product_id: item.variantIds[0],
                          variant_id: item.variantCode || ''
                          }
                      }
                  }

                  else {
                      route = {
                        name: Routes.CatalogProduct,
                        params: {
                          product_id: item.id
                        }
                      }
                  }

                  return {
                      id: item.id || item.variantIds?.[0] || '',
                      image: item.imageUrl || '',
                      title: item.title,
                      price: item.minPrice,
                      quantity: item.quantity,
                      route
                  }
              })
          } catch (error: any) {
              console.error(error)
              this.$_showErrorToast(`Une erreur s'est produite pendant le chargement des produits à la une.`)
          } finally {
              this.topProductsLoaded = true
          }*/

          // Load commercial banners
          try {
              const bannersApiPath = `/banners/commercials?institutionsBannerDashboard.institution_id=0&institutionsBannerDashboard.institution_id=${this.$_currentInstitutionId}`
              const bannersResponse: AxiosResponse<any> = await Http.apis.strapi.get(bannersApiPath)

              this.banners = this.$_checkBanners(bannersResponse.data[0])

          } catch (error: any) {
              this.$_showErrorToast(`Une erreur s'est produite pendant le chargement de la bannière.`)
          } finally {
              this.bannersLoaded = true
          }

          // Load article banners
          try {
              const bannersApiPath = `/banners/articles?institutionsBannerArticlesDashboard.institution_id=0&institutionsBannerArticlesDashboard.institution_id=${this.$_currentInstitutionId}`
              const bannersResponse: AxiosResponse<any> = await Http.apis.strapi.get(bannersApiPath)

              this.helpBanners = this.$_checkBanners(bannersResponse.data[0])

          } catch (error: any) {
              this.$_showErrorToast(`Une erreur s'est produite pendant le chargement des bannières.`)
          } finally {
              this.helpBannerLoaded = true
          }

      } finally {
          const sto = setTimeout(() => {
              this.$vs.loading.close()
              clearTimeout(sto)
          }, 1)
      }
  },
})
