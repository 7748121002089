
















import Vue, { PropType } from 'vue'

import { MarketingBannerInterface } from '@/types/components'

import Slider from '@/components/Slider.vue'
import MarketingBanner from '@/components/banners/marketing-banners/MarketingBanner.vue'

export default Vue.extend({
    components: {
        Slider,
        MarketingBanner
    },
    props: {
        banners: {
            type: Array as PropType<MarketingBannerInterface[]>,
            default: () => []
        }
    }
})
