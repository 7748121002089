import Vue from 'vue'
import isAfter from 'date-fns/isAfter'
import { BannerDTO, BannerItem } from '@/types/cms-api'

export default Vue.extend({
    methods: {
        $_checkBanners(bannerDTO: BannerDTO | undefined): BannerItem[] {
            const bannerItems = bannerDTO?.bannerItems
            const startingDate = bannerDTO?.startingDate ? new Date(bannerDTO.startingDate) : null
            const finishingDate = bannerDTO?.finishingDate ? new Date(bannerDTO.finishingDate) : null
            const todayDate = new Date()
                
            if (bannerItems && bannerItems.length) {
                if (
                    (!startingDate && !finishingDate) ||
                    (startingDate && !finishingDate && isAfter(todayDate, startingDate)) ||
                    (finishingDate && !startingDate && isAfter(finishingDate, todayDate)) ||
                    (startingDate && finishingDate && isAfter(todayDate, startingDate) && isAfter(finishingDate, todayDate))
                ) {
                    return bannerItems
                }
            }

            return []
        }
    }
})