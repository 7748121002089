


















import Vue from 'vue'

import { HCard } from '@happytal/bo-ui-library'

import MarkdownContent from '@/components/MarkdownContent.vue'

export default Vue.extend({
  components: {
    HCard,
    MarkdownContent
  },
  props: {
    avatarUrl: {
      type: String,
      default: null
    },
    title: {
      type: String,
      required: true
    },
    message: {
      type: String,
      required: true
    }
  }
})
