import Vue from 'vue'
import { sumBy } from 'lodash'
import { SuggestionDto, ServiceModelServicesDto, ProductOffersDto, VariantDataDto } from "@happytal/happydom-api"
import {
    getDescriptionAsHtml,
    getProductOptionsAsHtml,
    getServiceOptionsAsHtml
} from '@/modules/services'
import Routes from '@/constants/routes'
import { Location } from 'vue-router'

export default Vue.extend({
  methods: {
    $_getSuggestionTitle(suggestion: SuggestionDto): string {
      return (
        suggestion.suggestionProduct?.title ||
        suggestion.suggestionService?.title ||
        suggestion.suggestionOpen?.shortDescription ||
        ""
      )
    },
    $_getSuggestionDescription(suggestion: SuggestionDto, productOffers: ProductOffersDto[], variantData: VariantDataDto[], serviceModelServices: ServiceModelServicesDto[]): string {
      const description = suggestion.suggestionProduct?.description ||
      suggestion.suggestionService?.description ||
      suggestion.suggestionOpen?.description ||
      ""
      if (suggestion.suggestionProduct) {
        const productOffer = productOffers.find(po => po.offers?.find(o => o.offerId === suggestion.suggestionProduct?.offerId))
        if (productOffer) {
          const offer = productOffer.offers?.find(o => o.offerId === suggestion.suggestionProduct?.offerId)
          const options = getProductOptionsAsHtml({
            product: productOffer.product,
            offer: offer,
            variantInfo: {
              data: variantData
            }
          })
          return getDescriptionAsHtml(description, options)
        }
      }
      if (suggestion.suggestionService) {
        const serviceModelService = serviceModelServices.find(sms => sms.services?.find(s => s.code === suggestion.suggestionService?.code))
        if (serviceModelService) {
          const service = serviceModelService.services?.find(s => s.code === suggestion.suggestionService?.code)
          const options = getServiceOptionsAsHtml({
            meta: {
              service,
              serviceModel: serviceModelService.serviceModel,
              options: suggestion.suggestionService.options
            }
          })
          return getDescriptionAsHtml(description, options)
        }
      }
      return description
    },
    $_getSuggestionPrice(suggestion: SuggestionDto): number | undefined {
      if (suggestion.suggestionService) {
        const options = suggestion.suggestionService.options ?? []
        return suggestion.suggestionService.unitPrice +
            sumBy(options, option => {
              const values = option.values ?? []
              return option.unitPrice + sumBy(values, value => value.unitPrice)
            })
      }
      return (
        suggestion.suggestionProduct?.unitPrice
      )
    },
    $_getSuggestionQuantity(suggestion: SuggestionDto): number | undefined {
      return (
        suggestion.suggestionProduct?.quantity ||
        suggestion.suggestionService?.quantity
      )
    },
    $_getSuggestionImage(suggestion: SuggestionDto, serviceModelServices: ServiceModelServicesDto[] = []): string | undefined {
      if (suggestion.suggestionService) {
        const serviceModelService = serviceModelServices.find(sms => sms.services?.find(s => s.code === suggestion.suggestionService?.code))
        if (serviceModelService) {
          return (serviceModelService.serviceModel?.publicData as any)?.imageUrl
        }
      }
      return (
        suggestion.suggestionProduct?.imageUrl ||
        suggestion.suggestionService?.imageUrl
      )
    },
    $_getSuggestionStatusTextAndColor(
      suggestion: SuggestionDto
    ): { label: string; color: string } | undefined {
      switch (suggestion.status) {
        case "Refused":
          return {
            label: "Refusé",
            color: "#FFA253",
          }
        case "Expired":
          return {
            label: "Sans réponse",
            color: "#5099F4",
          }
        case "Fulfilled":
          return {
            label: "Pris en charge via happydom",
            color: "#28C76F",
          }
        case "FulfilledExternaly":
          return {
            label: "Pris en charge",
            color: "#28C76F",
          }
        case "AlreadyFulfilled":
          return {
            label: "Déjà commandé",
            color: "#28C76F",
          }
        default:
          return {
            label: "",
            color: "#17D3C3",
          }
      }
    },
    $_getOrderTypeFromBuyMode(suggestion: SuggestionDto): string | undefined {
      switch (suggestion.orderType) {
        case "OneShot":
          return "once"
        case "Recurring":
          return "subscription"
        default:
          return ""
      }
    },
    $_getPaymentTypeLabel(suggestion: SuggestionDto): string  {
      switch (suggestion.orderType) {
        case 'Recurring': case 'Reccurring': return 'Abonnement';
        case 'OneShot': return 'Une seule fois';
        default: return '-'
      }
    },
    $_getFrequencyLabel(suggestion: SuggestionDto): string  {
      switch (suggestion.orderType) {
        case 'Recurring': case 'Reccurring': {
          const frequencyInMonth = (
            suggestion.suggestionProduct?.frequencyInMonth ||
            suggestion.suggestionService?.frequencyInMonth ||
            1
          )
          return `Abonnement : tous les ${frequencyInMonth} mois`
        }
        case 'OneShot': default: return 'Une seule fois'
      }
    },
    $_getSuggestionRoute(suggestion: SuggestionDto, productOffers: ProductOffersDto[], variantData: VariantDataDto[], serviceModelServices: ServiceModelServicesDto[]):  Location | undefined {
      if (suggestion.suggestionProduct) {
        const productId = suggestion.suggestionProduct.productSku
        const productOffer = productOffers.find(po => po.product?.productSku === suggestion.suggestionProduct?.productSku)
        const variantCode: string | undefined = productOffer ? (productOffer as any)?.product?.data?.variantCode : undefined

        if (productId && productOffer && variantCode) {
          return {
            name: Routes.CatalogVariant,
            params: {
              product_id: productId,
              variant_id: variantCode
            }
          }
        }

        else if (productId && productOffer ) {
          return {
            name: Routes.CatalogProduct,
            params: {
              product_id: productId
            }
          }
        }
      }

      if (suggestion.suggestionService) {
        const serviceModelService = serviceModelServices.find(sms => sms.services?.find(s => s.code === suggestion.suggestionService?.code))
        const serviceModelId = serviceModelService ? serviceModelService.serviceModel?.code : undefined

        return serviceModelId ? ({
          name: Routes.CatalogService,
          params: {
            service_model_id: serviceModelId
          }
        }) : undefined
      }

      return undefined
    },
    $_doesProductHaveAnOffer(suggestion: SuggestionDto, productOffers: ProductOffersDto[]): boolean {
      return  Boolean(productOffers.find(po => po.offers?.find(o => o.offerId === suggestion.suggestionProduct?.offerId)))
    },
    $_doesServiceHaveAModel(suggestion: SuggestionDto, serviceModelServices: ServiceModelServicesDto[]): boolean {
      return  Boolean(serviceModelServices.find(sms => sms.services?.find(s => s.code === suggestion.suggestionService?.code)))
    },
  },
})
