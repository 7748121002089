




import Vue from 'vue'

import { VueCSSClass } from '@/types/index'

import MarkdownContent from '@/components/MarkdownContent.vue'

export default Vue.extend({
    components: {
        MarkdownContent
    },

    props: {
        description: {
            type: String,
            required: true
        },
        color: String,
    },

    computed: {
        descriptionClass(): VueCSSClass {
            return [
                'description', 
                { 
                    'white--text': !this.color,
                    'is-desktop': this.$breakpoint.mdAndUp 
                }, 
                
            ]
        }
    }
})
